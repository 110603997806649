import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route} from 'react-router-dom';
import Layout from '../container/profile/authentication/Default/Layout';

const Default = lazy(() => import('../container/profile/authentication/Default/NotFount'));

function NotfoundRoutes() {
  // const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/" component={Default} />
      </Suspense>
    </Switch>
  );
}

export default Layout(NotfoundRoutes);
