import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

// const TANDC = lazy(() => import('../container/profile/authentication/T&C/term&cont'))
const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));

function FrontendRoutes() {
  // const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >

        {/* <Route exact path="/" component={TANDC} /> */}
        <Route exact path="/" component={Login} />
      </Suspense>
    </Switch>
  );
}

export default AuthLayout(FrontendRoutes);
