/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Layout, Row, Col } from 'antd';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
// import TopMenu from './TopMenu';
import { Div } from './style';


const { Footer, Content } = Layout; //  Header,

const ThemeLayout = (WrappedComponent) => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {

      };
    }

    render() {

      const { ChangeLayoutMode } = this.props; // , rtl, topMenu 
      const currentYear = new Date().getFullYear();
      const darkMode = ChangeLayoutMode;
      const TextColor = localStorage.getItem("TXT")
      const CardBackGround = localStorage.getItem('CBG');
      const footerStyle = {
        padding: '20px 30px 18px',
        color: `${TextColor} !important`,
        fontSize: '14px',
        background: CardBackGround,
        width: '100%',
        boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
      };

      return (
        <Div darkMode={darkMode}>
          <Layout className="layout">
            {/* <Header
              style={{
                position: 'fixed',
                width: '100%',
                height:"55px",
                top: 0,
                [!rtl ? 'left' : 'right']: 0,
              }}
            >
              <Row>
                <Col lg={!topMenu ? 4 : 3} sm={6} xs={12} className="align-center-v navbar-brand">
                  {!topMenu || window.innerWidth <= 991 ? (""
                  ) : null}
                  <div
                    className={topMenu && window.innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'}
                  >
                    <img
                      src={
                        !darkMode
                          ? require(`../static/img/Logo_Dark.svg`).default
                          : require(`../static/img/Logo_Dark.svg`).default
                      }
                      alt=""
                    />
                  </div>
                </Col>
                <Col lg={!topMenu ? 14 : 15} md={8} sm={0} xs={0}>
                  {topMenu && window.innerWidth > 991 ? <TopMenu /> : <></>}
                </Col>
                <Col lg={6} md={10} sm={0} xs={0} />
                <Col md={0} sm={18} xs={12} />
              </Row>
            </Header> */}
            <Layout className="atbd-main-layout">
              <Content>
                <WrappedComponent {...this.props} />
                <Footer className="admin-footer" style={footerStyle}>
                  <Row>
                    <Col md={12} xs={24}>
                      <a href="http://prizmatec.in/" className="admin-footer__link" target="_blank" rel="noopener noreferrer">
                        <span className="admin-footer__copyright" style={{color:TextColor,fontWeight:"bold"}}>Powered by Prism Software @ {currentYear} </span>
                      </a>
                      {/* <a href="http://www.onebitconsult.com/" className="admin-footer__link"target="_blank" rel="noopener noreferrer">
                      <span className="admin-footer__copyright">Powered by Onebitconsult @ 2023 </span>
                      </a> */}
                    </Col>
                  </Row>
                </Footer>
              </Content>
            </Layout>
          </Layout>
        </Div>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      ChangeLayoutMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  };

  LayoutComponent.propTypes = {
    ChangeLayoutMode: propTypes.bool,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    changeRtl: propTypes.func,
    changeLayout: propTypes.func,
    changeMenuMode: propTypes.func,
  };

  return connect(mapStateToProps)(LayoutComponent);
};
export default ThemeLayout;
