import React from 'react';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

function ProtectedRoute({ component, path }) {
  const isLoggedIn = localStorage.getItem("user_token");
  return isLoggedIn ? <Route component={component} path={path} /> : <Redirect to="/" />;
}

ProtectedRoute.propTypes = {
  component: propTypes.object.isRequired,
  path: propTypes.string.isRequired, 
};


function ProtectedRouter({ component, path }) {
  const menuToken = localStorage.getItem("menu_token");
  return menuToken ? <Redirect to={`/Menu?access_token=${menuToken}`}/> : <Route component={component} path={path} />;
}
ProtectedRouter.propTypes = {
  component: propTypes.object.isRequired,
  path: propTypes.string.isRequired, 
};
export  { ProtectedRoute, ProtectedRouter }
