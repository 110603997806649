import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import axios from 'axios';
import { Aside, Content } from './overview/style';
import { getBaseURL } from '../../../Api/ApiConfig';
import Heading from '../../../components/heading/heading';
import '../../ecommerce/product/overlay.css'


const AuthLayout = (WraperContent) => {

  return function () {
    // const Url = window.location.pathname;
    const MainColorLight = localStorage.getItem('MCLT');
    const TextColor = localStorage.getItem("TXT")

    const [state, setState] = useState()
    const [img, setImg] = useState()
    useEffect(() => {
      async function getResturant() {
        const restaurant = localStorage.getItem("restaurant")
        const tokens = localStorage.getItem("menu_token")
        const headers = {};
        headers.Authorization = `Bearer ${tokens}`;
        if (tokens !== undefined) {
          const datas = await axios.get(`${getBaseURL()}/smartPrism/getByRestaurantId/${restaurant}`)
          if (datas.data.statusCode === 200) {
            setState(datas.data.data.restaurantName)
            setImg(datas.data.data.resLogo)
          }
        }
      }
      getResturant();
    }, [])

    return (
      <Row style={{ fontFamily: 'Roboto, sans-serif', fontweight: 300 }}>
        <Col xxl={8} xl={9} lg={12} md={8} xs={24} style={{ backgroundColor: MainColorLight }}>
          <Aside>
            <div className="auth-side-content">
              <img src={require('../../../static/img/auth/topShape.png')} alt="" className="topShape" />
              <img src={require('../../../static/img/auth/bottomShape.png')} alt="" className="bottomShape" />
              <Content>
                {/* <img style={{ width: '150px' }} src={require('../../../static/img/Logo_Dark.svg')} alt="" /> */}
                <br />
                <br />
                <Heading as="h1">
                  {/* {!Url.includes("/login") ?
                    <img style={{ width: '150px' }} src={require('../../../static/img/smart_prism.png')} alt="" />
                    :
                    <>
                      {img !== null || img !== undefined ? <img style={{ height: '100px', width: '150px' }} src={require(`../../../static/img/restaurant/${img}`)} alt="" /> : <img style={{ width: '150px' }} src={require('../../../static/img/smart_prism.png')} alt="" />}
                    </>
                  }
                  <br />
                  <br />
                  {Url !== "/login" ? "Smart Prism Application" : <h4>{state}</h4>} */}

                  {img !== null && img !== undefined ? <img style={{ width: '150px' }} src={img} alt="" /> : <img style={{ width: '150px' }} src={require('../../../static/img/smart_prism.png')} alt="" />}
                  <br />
                  <br />
                  <h4 className="text_color_primary" style={{ color: TextColor }}>{state}</h4>
                </Heading>
              </Content>
            </div>
          </Aside>
        </Col>

        <Col xxl={16} xl={15} lg={12} md={16} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;