import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Spin } from 'antd';
import axios from 'axios';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { getBaseURL } from "../../Api/ApiConfig";
import withAdminLayout from '../../layout/withAdminLayout';
import CallSteward from '../../container/ecommerce/popup/callSteward';
import '../../container/ecommerce/product/overlay.css'

const Menu = lazy(() => import('../../container/ecommerce/product/Products'));
const Cart = lazy(() => import('../../container/ecommerce/Cart'));
const CheckOut = lazy(() => import('../../container/ecommerce/Invoice'));

const Admin = () => {
  const { path } = useRouteMatch();
  const menuCode = localStorage.getItem('menu_token');
  const CardBackGround = localStorage.getItem('CBG');
  // const MainColor = localStorage.getItem("MC")
  const TextColor = localStorage.getItem('TXT');

  // const [branch, setBranch] = useState()
  // const [location, setLocation] = useState()
  const [isCallSteward, setIsCallSteward] = useState()
  const [isLogin, setIsLogin] = useState()
  const [resBanner, setResBanner] = useState()
  const [type, setType] = useState()

  useEffect(() => {
    async function restaurant() {
      const restaurant = localStorage.getItem("restaurant")
      const data = await axios.get(`${getBaseURL()}/smartPrism/getByRestaurantId/${restaurant}`);
      if (data.data.statusCode === 200) {
        localStorage.setItem("res_img", data.data.data.resLogo)
        setType(data.data.data.category)
        // setLocation(data.data.data.location)
        // setBranch(data.data.data.branch)
        setIsCallSteward(data.data.data.callStewardOption)
        setIsLogin(data.data.data.loginOption)
        setResBanner(data.data.data.restaurantImage)
      }
    }
    restaurant();
  }, [])

  const containerStyle = {
    maxWidth: '100%',
    height: '170px',
    position: 'relative',
  };
  const updateContainerStyle = () => {
    if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      return {
        ...containerStyle,
        height: '350px',
      };
    }
    if (window.innerWidth >= 1024) {
      return {
        ...containerStyle,
        height: '350px',
      };
    }
    return containerStyle;
  };
  const updatedContainerStyle = updateContainerStyle();
  const imageStyle = {
    width: '100%',
    height: '100%',
    // objectFit: 'cover',
    objectPosition: 'center',
    display: 'block',
  };

  return (
    <>
      {/* <div
        style={{
          maxWidth: "100%",
          height: "160px", // 180px
          position: "relative",
          // backgroundColor: CardBackGround
        }}> */}
      <div style={updatedContainerStyle}
      // className="overlayStyle"
      >
        <img style={{ ...imageStyle }} src={resBanner} alt="" />
        {/* <div>
            <div style={{ textAlign: 'center' }}>
              {!resLogo ? <img style={{ height: '120px', width: '120px', marginBottom: 5 }} src={require('../../static/img/smart_prism.png')} alt="" /> : <img style={{ height: '120px', width: '120px', marginBottom: 5 }} src={resLogo} alt="" />}
            </div>
            <div className="textDiv">
               <h6 className='banner_text' style={{ color: TextColor,fontWeight: "bold",opacity: "90%"}}>{state} hide one</h6>
              <h6 className='banner_text text_color_secondary' style={{ color: TextColor }}>{location}</h6>
            </div>
          </div>
        </div>
      </div> */}
      </div>

      <Switch>
        <Suspense
          fallback={
            <div className="spin">
              <Spin />
            </div>
          }
        >
          <Route exact path={path} component={Menu} />
          <Route path={`${path}/?access_token=${menuCode}`} component={Menu} />
          <Route path={`${path}/cart`} component={Cart} />
          <Route path={`${path}/checkout`} component={CheckOut} />
        </Suspense>
      </Switch>

      {((type !== undefined && type === "Order") || (isCallSteward !== undefined && isCallSteward === "ENABLED")) && (
        <div
          className="call_overlay d-flex align-items-start justify-content-between"
          style={{
            backgroundColor: CardBackGround,
            // border: `2px solid ${MainColor}`,
            boxShadow: `0px 0px 5px 1px ${TextColor}50`
          }}>
          <div className='call_button'>
            {isCallSteward !== undefined && isCallSteward === "ENABLED" && <CallSteward isLogin={isLogin} />}
          </div>
        </div>
      )}

    </>

  );
}

export default withAdminLayout(Admin);



















